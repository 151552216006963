import React, { useState, useEffect } from 'react';

const DownloadPage = () => {
    const [versions, setVersions] = useState({
        windows: { version: '', description: '' },
        linuxDeb: { version: '', description: '' },
        linuxRpm: { version: '', description: '' }
    });

    const [activePlatform, setActivePlatform] = useState('');
    const [hidingPlatform, setHidingPlatform] = useState('');
    const [isOffline, setIsOffline] = useState(false);

    useEffect(() => {
        fetchLatestVersions();
        window.addEventListener('offline', () => setIsOffline(true));
        window.addEventListener('online', () => setIsOffline(false));
        return () => {
            window.removeEventListener('offline', () => setIsOffline(true));
            window.removeEventListener('online', () => setIsOffline(false));
        };
    }, []);

    const fetchLatestVersions = async () => {
        try {
            const [windowsResponse, linuxDebResponse, linuxRpmResponse] = await Promise.all([
                fetch('https://api.github.com/repos/bushcoding/windows-classify-client-download/releases/latest'),
                fetch('https://api.github.com/repos/bushcoding/linux-classify-client-deb-download/releases/latest'),
                fetch('https://api.github.com/repos/bushcoding/linux-classify-client-rpm-download/releases/latest')
            ]);

            if (windowsResponse.ok) {
                const windowsData = await windowsResponse.json();
                setVersions(prev => ({
                    ...prev,
                    windows: {
                        version: windowsData.tag_name.replace('v', ''),
                        description: windowsData.body || 'Nothing new'
                    }
                }));
            } else {
                console.error('Failed to fetch latest release data for Windows.');
            }

            if (linuxDebResponse.ok) {
                const linuxDebData = await linuxDebResponse.json();
                setVersions(prev => ({
                    ...prev,
                    linuxDeb: {
                        version: linuxDebData.tag_name.replace('v', ''),
                        description: linuxDebData.body || 'Nothing new'
                    }
                }));
            } else {
                console.error('Failed to fetch latest release data for Linux (DEB).');
            }

            if (linuxRpmResponse.ok) {
                const linuxRpmData = await linuxRpmResponse.json();
                setVersions(prev => ({
                    ...prev,
                    linuxRpm: {
                        version: linuxRpmData.tag_name.replace('v', ''),
                        description: linuxRpmData.body || 'Nothing new'
                    }
                }));
            } else {
                console.error('Failed to fetch latest release data for Linux (RPM).');
            }

        } catch (error) {
            console.error('Error fetching latest release:', error);
        }
    };

    const constructDownloadUrl = (platform, packageType) => {
        const { windows, linuxDeb, linuxRpm } = versions;
        let downloadUrl = '';

        switch (platform) {
            case 'windows':
                downloadUrl = `https://github.com/bushcoding/windows-classify-client-download/releases/latest/download/ClassifyInstaller-${windows.version}-win32.exe`;
                break;
            case 'linux':
                if (packageType === 'deb') {
                    downloadUrl = `https://github.com/bushcoding/linux-classify-client-deb-download/releases/latest/download/ClassifyInstaller-${linuxDeb.version}-linux.deb`;
                } else if (packageType === 'rpm') {
                    downloadUrl = `https://github.com/bushcoding/linux-classify-client-rpm-download/releases/latest/download/ClassifyInstaller-${linuxRpm.version}-linux.rpm`;
                }
                break;
            default:
                console.error('Unsupported platform.');
                return '';
        }
        if (downloadUrl.startsWith('https://github.com/') && downloadUrl.includes('/releases/latest/download/')) {
            return downloadUrl;
        } else {
            console.error('Invalid download URL:', downloadUrl);
            return '';
        }
    };

    const handleDownload = (platform, packageType) => {
        if (isOffline) {
            console.error('Cannot download while offline.');
            return;
        }

        const downloadUrl = constructDownloadUrl(platform, packageType);
        if (downloadUrl) {
            // Create a temporary download link
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = ''; // Optionally, specify the filename here if needed
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Failed to construct a valid download URL.');
        }
    };

    const handlePlatformClick = (platform) => {
        if (activePlatform === platform) {
            setHidingPlatform(platform);
            setTimeout(() => {
                setHidingPlatform('');
                setActivePlatform('');
            }, 300); // Delay to match animation duration
        } else {
            if (activePlatform) {
                setHidingPlatform(activePlatform);
                setTimeout(() => {
                    setHidingPlatform('');
                    setActivePlatform(platform);
                }, 300); // Delay to match animation duration
            } else {
                setActivePlatform(platform);
            }
        }
    };

    return (
        <div className="bg-gradient-to-r from-blue-400 via-blue-300 to-blue-300 text-white py-16 text-center flex items-center justify-center min-h-screen">
            <div className="flex flex-col md:flex-row items-start justify-center px-6 py-8 lg:py-0">
                <div className="w-full md:w-1/2 md:mr-8 rounded-lg shadow border bg-gray-800 border-gray-700 p-6 max-w-md mx-auto">
                    <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">Download</h1>
                    <p className="text-gray-300 mb-4">Download the latest version of Classify</p>
                    <button
                        onClick={() => handleDownload('windows')}
                        className="w-full bg-blue-500 text-white hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                        Download Classify for Windows (v{versions.windows.version})
                    </button>
                    <button
                        onClick={() => handleDownload('linux', 'deb')}
                        className="w-full bg-green-500 text-white hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                        Download Classify for Linux (DEB) (v{versions.linuxDeb.version})
                    </button>
                    <button
                        onClick={() => handleDownload('linux', 'rpm')}
                        className="w-full bg-yellow-500 text-white hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                        Download Classify for Linux (RPM) (v{versions.linuxRpm.version})
                    </button>
                </div>
                <div className="w-full md:w-1/2 mt-8 md:mt-0 rounded-lg shadow border bg-gray-800 border-gray-700 p-6 max-w-md mx-auto">
                    <h2 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">Release Notes</h2>
                    <div className="space-y-4 mt-4">
                        {['windows', 'linuxDeb', 'linuxRpm'].map(platform => (
                            <div key={platform} className="border-t border-gray-700 pt-4">
                                <button
                                    onClick={() => handlePlatformClick(platform)}
                                    className={`w-full text-left ${platform === 'windows' ? 'text-blue-300' : platform === 'linuxDeb' ? 'text-green-300' : 'text-yellow-300'} hover:text-${platform === 'windows' ? 'blue' : platform === 'linuxDeb' ? 'green' : 'yellow'}-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium text-sm px-5 py-2.5`}>
                                    {platform === 'windows' ? 'Windows' : platform === 'linuxDeb' ? 'Linux (DEB)' : 'Linux (RPM)'} (v{versions[platform].version})
                                </button>
                                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${hidingPlatform === platform ? 'max-h-0 opacity-0' : activePlatform === platform ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'}`}>
                                    <div className="mt-2 text-gray-300 text-left pl-5 whitespace-pre-line">
                                        <p>{versions[platform].description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadPage;
