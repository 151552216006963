import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAuthenticated = localStorage.getItem('token') !== null;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.classList.remove('overflow-hidden');
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <div>
      <nav className="bg-blue-400 shadow-lg fixed w-full z-20 top-0 start-0">
        <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={process.env.PUBLIC_URL + '/classifyLBSF.svg'} className="h-8" alt="classify logo, bush coding software" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">Classify</span>
          </Link>
          <div className="hidden md:flex space-x-4">
            <NavLink to="/" className="rounded hover:bg-blue-500 hover:shadow-inner px-3 py-1 text-white font-medium transition duration-300">Home</NavLink>
            <NavLink to="/download" className="rounded hover:bg-blue-500 hover:shadow-inner px-3 py-1 text-white font-medium transition duration-300">Download</NavLink>
            <NavLink to="/about" className="rounded hover:bg-blue-500 hover:shadow-inner px-3 py-1 text-white font-medium transition duration-300">About</NavLink>
            <NavLink to="/help" className="rounded hover:bg-blue-500 hover:shadow-inner px-3 py-1 text-white font-medium transition duration-300">Help</NavLink>
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="inline-flex items-center p-2 w-10 h-10 justify-center text-white">
              <span className="sr-only">Open main menu</span>
              <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="w-5 h-5" />
            </button>
          </div>
          {isAuthenticated ? (
            <div className="hidden md:flex items-center space-x-4">
              <Link to="/profile" className="bg-blue-500 shadow-inner text-white font-medium px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
                Profile
              </Link>
            </div>
          ) : (
            <div className="hidden md:flex items-center space-x-4">
              <Link to="/login" className="hover:bg-blue-500 hover:shadow-inner px-4 py-2 rounded text-white font-medium transition duration-300">
                Log In
              </Link>
              <Link to="/register" className="bg-blue-500 shadow-inner text-white font-medium px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
                Get started
              </Link>
            </div>
          )}
        </div>
      </nav>
      {isMenuOpen && (
        <div className="md:hidden fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75" onClick={closeMenu}>
          <div className="flex justify-end p-4">
            <FontAwesomeIcon icon={faTimes} className="text-white w-8 h-8" />
          </div>
          <div className="flex flex-col items-center p-8">
            <NavLink
              to="/"
              className="text-lg text-white hover:text-gray-400 font-medium transition duration-300 p-1"
            >
              Home
            </NavLink>
            <NavLink
              to="/download"
              className="text-lg text-white hover:text-gray-400 font-medium transition duration-300 p-1"
            >
              Download
            </NavLink>
            <NavLink
              to="/about"
              className="text-lg text-white hover:text-gray-400 font-medium transition duration-300 p-1"
            >
              About
            </NavLink>
            <NavLink
              to="/help"
              className="text-lg text-white hover:text-gray-400 font-medium transition duration-300 p-1"
            >
              help
            </NavLink>
            {isAuthenticated ? (
              <Link to="/profile" className="bg-blue-500 text-white font-medium px-4 py-2 rounded hover:bg-blue-600 p-1">
                Profile
              </Link>
            ) : (
              <>
                <Link to="/login" className="text-lg text-white hover:text-gray-400 font-medium transition duration-300 p-1">
                  Log in
                </Link>
                <Link to="/register" className="bg-blue-500 text-white text-lg font-medium px-4 py-2 rounded hover:bg-blue-600 p-1">
                  Get started
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
