import React from 'react';

const HelpPage = () => {
  return (
    <div className="bg-gradient-to-r from-blue-400 via-blue-300 to-blue-300 min-h-screen">
      <div className="container mx-auto py-32 text-white text-center px-5">
        <h1 className="text-4xl font-extrabold mb-4">Welcome to the Help Page!</h1>
        <p className="text-xl mb-8">Here you will find resources and answers to frequently asked questions to make your experience easier.</p>
        <div className="mt-8">
          <p className="text-4xl font-extrabold mb-4">User Manual - Classify</p>
          <p className="text-3xl font-semibold mb-2">Software Installation</p>
          <p className="text-xl mb-8">
            1. Download the installer from our official website.<br />
            2. Run the downloaded file and follow the on-screen instructions.<br />
            3. Once the installation is complete, open Classify from the applications menu.
          </p>

          <p className="text-3xl font-semibold mb-2">Use of the Application</p>
          <p className="text-xl mb-8">
            1. Open Classify.<br />
            2. Click the "Register" button.<br />
            3. Complete the name, email, and password fields, then click "Register."
          </p>

          <p className="text-3xl font-semibold mb-2">Register</p>
          <p className="text-xl mb-8">
            1. Open Classify.<br />
            2. Click the "Register" button.<br />
            3. Enter your name, email and password, then click "Register."
          </p>

          <p className="text-3xl font-semibold mb-2">Login</p>
          <p className="text-xl mb-8">
            1. Open Classify.<br />
            2. Click the "Login" button.<br />
            3. Enter your email and password, then click "Login."
          </p>

          <p className="text-3xl font-semibold mb-2">File Organization</p>
          <p className="text-xl mb-8">
            1. Click the "Browse" button to select a folder.<br />
            2. Click "Organize" to sort the files by type.
          </p>

          <p className="text-3xl font-semibold mb-2">Software Update</p>
          <p className="text-xl mb-8">
            Classify checks for updates automatically. When an update is available, follow the on-screen instructions to update and do not close the program until it finishes.
          </p>

          <p className="text-3xl font-semibold mb-2">Troubleshooting</p>
          <p className="text-xl mb-8">
            <strong>Error registering:</strong> Make sure all fields are complete and correct.<br />
            <strong>Login error:</strong> Please verify your email and password.<br />
            If the problems persist then contact support: <b className='break-all'>support@bushcoding.freshdesk.com</b>
          </p>

          <p className="text-3xl font-semibold mb-2">Support and Contact</p>
          <p className="text-xl mb-8">
            For technical support, contact our team at <b className='break-all'>support@bushcoding.freshdesk.com</b>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
