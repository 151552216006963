import React from "react";

const NotFoundPage = () => {
  return (
    <div className="bg-gradient-to-r from-blue-400 via-blue-300 to-blue-300 min-h-screen  flex items-center justify-center">
      <div className="text-white py-16 text-center flex items-center justify-center h-screen">
        <div className="container mx-auto">
          <h1 className="text-5xl font-extrabold mb-4">404 - Page not found</h1>
          <p className="text-xl mb-8">It seems that the page you are looking for does not exist. Would you like to return to the <a href="/" className="font-bold hover:text-blue-500 hover:underline">home page</a>?</p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
