import axios from '../axiosConfig';
import { jwtDecode } from 'jwt-decode';

export const renewToken = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('User not authenticated');
            return;
        }
        const decodedToken = jwtDecode(token);
        const response = await axios.post('/revalidate-token', {
            email: decodedToken.email,
        });
        localStorage.setItem('token', response.data.token);
        console.log('Token JWT renovado con éxito:', response.data.token);
    } catch (error) {
        console.error('Error al renovar el token JWT:', error.message);
    }
};

export const isLoggedIn = () => {
    const token = localStorage.getItem('token');
    return !!token;
};

export const isNotLoggedIn = () => {
    const token = localStorage.getItem('token');
    return !token;
};
