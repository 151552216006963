import React, { createContext, useContext, useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem('token'));
    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        setToken(null);
        navigate('/login');
        window.location.reload();
    }, [navigate]);
    useEffect(() => {
        const checkTokenExpiration = () => {
            if (token) {
                const decoded = jwtDecode(token);
                const currentTime = Math.floor(Date.now() / 1000);
                if (decoded.exp < currentTime) {
                    handleLogout();
                }
            }
        };
        checkTokenExpiration();
        const intervalId = setInterval(checkTokenExpiration, 60000);
        return () => clearInterval(intervalId);
    }, [token, handleLogout]);
    return (
        <AuthContext.Provider value={{ token, setToken, handleLogout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
