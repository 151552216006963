import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from '../axiosConfig';
import { isNotLoggedIn } from '../controllers/utils';

const ProfilePage = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const [decodedToken, setDecodedToken] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [isDeletingAccount, setIsDeletingAccount] = useState(false);
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        daPassword: '',
    });
    const [formData, setFormData] = useState({
        uid: '',
        name: '',
        email: ''
    });
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showDaPassword, setShowDaPassword] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (isNotLoggedIn()) {
            navigate('/login');
        }
    }, [navigate]);

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        navigate('/login');
        window.location.reload();
    }, [navigate]);

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setDecodedToken(decoded);
            setFormData({ uid: decoded.uid, name: decoded.name, email: decoded.email });
            const currentTime = Math.floor(Date.now() / 1000);
            if (decoded.exp < currentTime) {
                handleLogout();
            }
        }
    }, [token, handleLogout]);

    const handleUpgradePlan = () => {
        navigate('/upgrade');
    };

    const formatPremiumExpiry = (expiry) => {
        const expiryDate = new Date(expiry);
        const day = expiryDate.getDate();
        const month = expiryDate.getMonth() + 1;
        const year = expiryDate.getFullYear();
        const hours = expiryDate.getHours();
        const minutes = expiryDate.getMinutes();
        const seconds = expiryDate.getSeconds();
        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setFormData({ uid: decodedToken.uid, name: decodedToken.name, email: decodedToken.email });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const togglePasswordVisibility = (field) => {
        switch (field) {
            case 'currentPassword':
                setShowCurrentPassword(!showCurrentPassword);
                break;
            case 'newPassword':
                setShowNewPassword(!showNewPassword);
                break;
            case 'confirmPassword':
                setShowConfirmPassword(!showConfirmPassword);
                break;
            case 'daPassword':
                setShowDaPassword(!showDaPassword);
                break;
            default:
                console.error(`Unknown field: ${field}`);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/update-profile', formData);
            const { token } = response.data;
            if (token) {
                localStorage.setItem('token', token);
                const decoded = jwtDecode(token);
                setDecodedToken(decoded);
                setIsEditing(false);
            } else {
                console.error('Failed to update profile:', response.data);
            }
        } catch (error) {
            setError(error.response?.data?.message || error.message);
            console.error('Reset Password Error:', error.response?.data?.message || error.message);
        }
    };

    const handleChangePassword = (e) => {
        const { name, value } = e.target;
        setPasswordData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleChangePasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/change-password', {
                uid: formData.uid,
                currentPassword: passwordData.currentPassword,
                newPassword: passwordData.newPassword,
                confirmPassword: passwordData.confirmPassword,
                daPassword: passwordData.daPassword
            });
            if (response.data.status === true) {
                setIsChangingPassword(false);
                setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '', daPassword: '' });
            } else {
                console.error('Failed to change password:', response.data);
            }
        } catch (error) {
            setError(error.response?.data?.message || error.message);
            console.error('Reset Password Error:', error.response?.data?.message || error.message);
        }
    };

    const handleDeleteAccount = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/delete-account', {
                uid: formData.uid,
                daPassword: passwordData.daPassword,
            });
            if (response.data.status === true) {
                setIsDeletingAccount(false);
                handleLogout();
            } else {
                console.error('Failed to delete account:', response.data);
            }
        } catch (error) {
            setError(error.response?.data?.message || error.message);
            console.error('Reset Password Error:', error.response?.data?.message || error.message);
        }
    };


    const clearError = () => {
        setError('');
    };

    useEffect(() => {
        return () => {
            setError('');
        };
    }, [isEditing, isChangingPassword, isDeletingAccount]);

    return (
        <div className="break-all bg-gradient-to-r from-blue-400 via-blue-300 to-blue-300 text-white py-16 text-center flex items-center justify-center min-h-screen">
            <div className="w-full md:mt-0 sm:max-w-md xl:p-0 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                {!isChangingPassword && !isDeletingAccount && !isEditing && (
                    <h2 className="text-5xl font-extrabold text-white text-center mb-4">User Profile</h2>
                )}
                {isEditing && (
                    <h2 className="text-5xl font-extrabold text-white text-center mb-4">Edit Profile</h2>
                )}
                {isChangingPassword && (
                    <h2 className="text-5xl font-extrabold text-white text-center mb-4">Reset Password</h2>
                )}
                {isDeletingAccount && (
                    <h2 className="text-5xl font-extrabold text-white text-center mb-4">Delete Account</h2>
                )}
                <div className="w-full rounded-lg shadow border bg-gray-800 border-gray-700 text-white p-6 mb-8">
                    {decodedToken ? (
                        isEditing ? (

                            <form onSubmit={handleSubmit}>
                                {error && (
                                    <div className="mb-4 p-3 bg-red-200 text-red-700 rounded relative">
                                        <p className="inline-block">{error}</p>
                                        <button
                                            onClick={clearError}
                                            className="absolute top-0 right-0 p-2 focus:outline-none"
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-700" />
                                        </button>
                                    </div>
                                )}
                                <div className="mb-4">
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full p-3 rounded pr-10 text-black"
                                    />
                                </div>
                                <div className="mb-4">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full p-3 rounded pr-10 text-black"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <button
                                        type="submit"
                                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 w-full"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleCancelEdit}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 w-full mt-4"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        ) : isChangingPassword ? (

                            <form onSubmit={handleChangePasswordSubmit}>
                                {error && (
                                    <div className="mb-4 p-3 bg-red-200 text-red-700 rounded relative">
                                        <p className="inline-block">{error}</p>
                                        <button
                                            onClick={clearError}
                                            className="absolute top-0 right-0 p-2 focus:outline-none"
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-700" />
                                        </button>
                                    </div>
                                )}
                                <div className="mb-4">
                                    <div className="flex items-center">
                                        <input
                                            type={showCurrentPassword ? 'text' : 'password'}
                                            name="currentPassword"
                                            placeholder="Current Password"
                                            value={passwordData.currentPassword}
                                            onChange={handleChangePassword}
                                            className="w-full p-3 rounded pr-10 text-black"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('currentPassword')}
                                            className="ml-[-30px] mr-3"
                                        >
                                            <FontAwesomeIcon
                                                icon={showCurrentPassword ? faEyeSlash : faEye}
                                                className="text-gray-500 hover:text-gray-700"
                                            />
                                        </button>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="flex items-center">
                                        <input
                                            type={showNewPassword ? 'text' : 'password'}
                                            name="newPassword"
                                            placeholder="New Password"
                                            value={passwordData.newPassword}
                                            onChange={handleChangePassword}
                                            className="w-full p-3 rounded pr-10 text-black"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('newPassword')}
                                            className="ml-[-30px] mr-3"
                                        >
                                            <FontAwesomeIcon
                                                icon={showNewPassword ? faEyeSlash : faEye}
                                                className="text-gray-500 hover:text-gray-700"
                                            />
                                        </button>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="flex items-center">
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            value={passwordData.confirmPassword}
                                            onChange={handleChangePassword}
                                            className="w-full p-3 rounded pr-10 text-black"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('confirmPassword')}
                                            className="ml-[-30px] mr-3"
                                        >
                                            <FontAwesomeIcon
                                                icon={showConfirmPassword ? faEyeSlash : faEye}
                                                className="text-gray-500 hover:text-gray-700"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <button
                                        type="submit"
                                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 w-full"
                                    >
                                        Change Password
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setIsChangingPassword(false)}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 w-full mt-4"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        ) : isDeletingAccount ? (

                            <form onSubmit={handleDeleteAccount}>
                                {error && (
                                    <div className="mb-4 p-3 bg-red-200 text-red-700 rounded relative">
                                        <p className="inline-block">{error}</p>
                                        <button
                                            onClick={clearError}
                                            className="absolute top-0 right-0 p-2 focus:outline-none"
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-700" />
                                        </button>
                                    </div>
                                )}
                                <div className="mb-4">
                                    <div className="flex items-center">
                                        <input
                                            type={showDaPassword ? 'text' : 'password'}
                                            name="daPassword"
                                            placeholder="Current Password"
                                            value={passwordData.daPassword}
                                            onChange={handleChangePassword}
                                            className="w-full p-3 rounded pr-10 text-black"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('daPassword')}
                                            className="ml-[-30px] mr-3"
                                        >
                                            <FontAwesomeIcon
                                                icon={showDaPassword ? faEyeSlash : faEye}
                                                className="text-gray-500 hover:text-gray-700"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <button
                                        type="submit"
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 w-full"
                                    >
                                        Delete Account
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setIsDeletingAccount(false)}
                                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 w-full mt-4"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <div>
                                <div className="my-1 flex">
                                    <p className="text-gray-400 font-medium">User ID: <span className="text-white">{decodedToken.uid}</span></p>
                                </div>
                                <div className="my-1 flex">
                                    <p className="text-gray-400 font-medium">Name: <span className="text-white">{decodedToken.name}</span></p>
                                </div>
                                <div className="my-1 flex">
                                    <p className="text-gray-400 font-medium">Email: <span className="text-white">{decodedToken.email}</span></p>
                                </div>
                                <div className="my-1 flex">
                                    <p className="text-gray-400 font-medium">Plan: <span className="text-white">{decodedToken.plan}</span></p>
                                </div>
                                {decodedToken.premiumExpiration !== null && (
                                    <div className="my-1 flex">
                                        <p className="text-gray-400 font-medium">Premium Expiry Date: <span className="text-white">{formatPremiumExpiry(decodedToken.premiumExpiration)}</span></p>
                                    </div>
                                )}
                                <button
                                    onClick={handleEdit}
                                    className="my-1 bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-700 w-full"
                                >
                                    Edit Profile
                                </button>
                                <button
                                    onClick={() => setIsChangingPassword(true)}
                                    className="my-1 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 w-full"
                                >
                                    Change Password
                                </button>


                                {decodedToken.plan === 'free' && (
                                    <div className="my-1">
                                        <button
                                            onClick={handleUpgradePlan}
                                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 w-full"
                                        >
                                            Upgrade Plan
                                        </button>
                                    </div>
                                )}
                                
                                <div className="my-1">
                                    <div className="flex justify-between">
                                        <p className="text-gray-400 font-medium">Session Created: <span className="text-white">{new Date(decodedToken.iat * 1000).toLocaleString()}</span></p>
                                    </div>
                                </div>
                                <div className="my-1">
                                    <div className="flex justify-between">
                                        <p className="text-gray-400 font-medium">Session Expires: <span className="text-white">{new Date(decodedToken.exp * 1000).toLocaleString()}</span></p>
                                    </div>
                                </div>
                                <button
                                    onClick={handleLogout}
                                    className="my-1 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 w-full"
                                >
                                    Logout
                                </button>
                                <button
                                    onClick={() => setIsDeletingAccount(true)}
                                    className="my-1 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 w-full"
                                >
                                    Delete Account
                                </button>
                            </div>
                        )
                    ) : (
                        <p>Loading profile data...</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
