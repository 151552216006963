import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import { jwtDecode } from 'jwt-decode';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { renewToken } from '../controllers/utils';
import { paypalClientId, prices } from '../config';



const UpgradePlan = () => {
    const navigate = useNavigate();
    const [selectedDuration, setSelectedDuration] = useState(() => {
        const storedDuration = localStorage.getItem('selectedDuration');
        return storedDuration || '1m';
    });
    const [userPlan, setUserPlan] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('User not authenticated');
            navigate('/');
            return;
        }
        const decodedToken = jwtDecode(token);
        setUserPlan(decodedToken.plan);
    }, [navigate]);

    const [paypalScriptLoaded, setPaypalScriptLoaded] = useState(false);

    useEffect(() => {
        const paypalScript = document.querySelector('script[src*="paypal.com"]');
        if (paypalScript) {
            setPaypalScriptLoaded(true);
        } else {
            setTimeout(() => {
                setPaypalScriptLoaded(true);
            }, 4000);
        }
    }, []);

    const handleDurationChange = (e) => {
        const newDuration = e.target.value;
        setSelectedDuration(newDuration);
        localStorage.setItem('selectedDuration', newDuration);
        window.location.reload(true);
    };

    const handlePurchase = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('User not authenticated');
                return;
            }
            const decodedToken = jwtDecode(token);
            const response = await axios.post('/upgrade-plan', {
                uid: decodedToken.uid,
                duration: selectedDuration,
            });
            await renewToken();
            console.log('Premium plan granted successfully:', response.data);
            setUserPlan(response.data.plan);
            localStorage.removeItem('selectedDuration');
            window.location.reload(true);
        } catch (error) {
            console.error('Error granting premium plan:', error.message);
        }
    };

    const onApprove = async (data, actions) => {
        console.log('Order ID:', data.orderID);
        console.log('Data:', data);
        return actions.order.capture().then(function (details) {
            console.log('Transaction completed by', details.payer.name.given_name);
            handlePurchase();
        }).catch(error => {
            console.error('Error capturing order:', error);
        });
    };

    return (
        <div className="break-all bg-gradient-to-r from-blue-400 via-blue-300 to-blue-300 text-white py-16 text-center flex items-center justify-center min-h-screen">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen lg:py-0">
                <h2 className="text-5xl font-extrabold text-white text-center my-8">Purchase Subscription</h2>
                <div className="w-full rounded-lg shadow border bg-gray-800 border-gray-700 text-white p-6 mb-8">
                    {userPlan === 'premium' ? (
                        <p>You are already a premium user. Enjoy the premium content!</p>
                    ) : (
                        <>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-400">Duration:</label>
                                <select
                                    value={selectedDuration}
                                    onChange={handleDurationChange}
                                    className="mt-1 p-2 border rounded w-full text-gray-800"
                                >
                                    <option value="1m">1 Month</option>
                                    <option value="1y">1 Year</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <p className="text-sm text-gray-400 font-medium">
                                    Amount: ${prices[selectedDuration]}
                                </p>
                            </div>
                            {paypalScriptLoaded ? (
                                <PayPalScriptProvider options={{ 'client-id': paypalClientId }}>
                                    <PayPalButtons
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: prices[selectedDuration],
                                                        },
                                                    },
                                                ],
                                                application_context: {
                                                    shipping_preference: 'NO_SHIPPING',
                                                },
                                            }).then(orderID => {
                                                console.log('Order created with ID:', orderID);
                                                return orderID;
                                            });
                                        }}
                                        onApprove={onApprove}
                                        onError={(err) => {
                                            if (err.code === 'prerender_initiate_payment_reject' && err.message === 'Error: Detected popup close') {
                                                console.error('Payment cancelled: popup closed');
                                            } else {
                                                console.error('Error during payment:', err);
                                            }
                                        }}
                                        style={{
                                            layout: 'vertical',
                                            color: 'blue',
                                            shape: 'rect',
                                            label: 'paypal',
                                        }}
                                    />
                                </PayPalScriptProvider>
                            ) : (
                                <p className='text-sm text-gray-400 font-medium'>Loading payment system...</p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UpgradePlan;
