import React from 'react';

const AboutPage = () => {
  return (
    <div className="bg-gradient-to-r from-blue-400 via-blue-300 to-blue-300 min-h-screen">
      <div className="container mx-auto py-32 text-white text-center px-5">
        <h1 className="text-4xl font-extrabold mb-4">About Us</h1>
        <p className="text-xl mb-8">
          Welcome to Classify, an innovative solution for file management and organization. Founded in 2022, our mission is to provide an efficient and easy-to-use tool that simplifies document and file management, helping users around the world save time and increase their productivity. We believe that technology should make life easier, not complicate it. We strive to create an app that is intuitive, powerful, and accessible to everyone. We want to empower our users with a tool that allows them to organize their files effectively, keeping their information secure and always within reach. We are committed to maintaining high quality standards in everything we do. From new feature development to customer support, every aspect of our operation is geared toward providing exceptional service.
        </p>

        <h1 className="text-4xl font-extrabold mb-4">Contact</h1>
        <p className="text-xl mb-8">
          We are always here to help you. If you have any questions, comments or suggestions, please do not hesitate to contact us. You can send us an email to <b className='break-all'>support@bushcoding.freshdesk.com</b>, you can also contact us through our <b><a rel="noreferrer" target='_blank' href='https://bushcoding.freshdesk.com/' className="text-white underline">support page</a></b> and you can also follow us on our <b><a rel="noreferrer" target='_blank' href='https://linktr.ee/bushcoding' className="text-white underline">social networks</a></b> to stay up to date with the latest news and updates.
        </p>

        <h1 className="text-4xl font-extrabold mb-4">Privacy Policy</h1>
        <p className="text-xl mb-8">
          <strong>1. Information We Collect</strong><br />
          1.1 Personal Information: Name, email, and any other information you provide when registering.<br />
          1.2 Usage Information: Data about how you use the application, including interactions and preferences.<br /><br />

          <strong>2. Use of Information</strong><br />
          2.1 We use the information collected to improve our service and personalize your experience.<br />
          2.2 We do not share your personal information with third parties, except when necessary to provide the service or when required by law.<br /><br />

          <strong>3. Security</strong><br />
          3.1 We implement security measures to protect your personal information against unauthorized access.<br /><br />

          <strong>4. User Rights</strong><br />
          4.1 You have the right to access, rectify and delete your personal information.<br />
          4.2 You can exercise these rights from the Classify website.<br /><br />

          <strong>5. Changes to this Policy</strong><br />
          5.1 We reserve the right to update this privacy policy at any time.<br />
          5.2 We will notify you of any changes through our application or website.<br /><br />

          <strong>6. Contact</strong><br />
          For any questions about this policy, you can contact us at: <b className='break-all'>support@bushcoding.freshdesk.com</b>
        </p>

        <h1 className="text-4xl font-extrabold mb-4">Terms & Conditions</h1>
        <p className="text-xl mb-8">
          <strong>1. Introduction</strong><br />
          Welcome to Classify. These terms and conditions govern your use of our service and access to our application. By using our application, you accept these terms in full.<br /><br />

          <strong>2. Use of the Service</strong><br />
          2.1 You must not use Classify in any way that is unlawful or prohibited by these terms.<br />
          2.2 Use of the service is at your own risk. Classify is not responsible for any damage that may arise from the use of the application.<br /><br />

          <strong>3. Premium Subscription</strong><br />
          3.1 The premium subscription is purchased through our website.<br />
          3.2 The subscription is valid for the time chosen during the purchase from when it was purchased.<br />
          3.3 After the subscription time has passed, you will have the free plan again, this to avoid automatic payments and problems with refunding money.<br />
          3.4 No refunds are offered for purchased subscriptions.<br /><br />

          <strong>4. Intellectual Property</strong><br />
          4.1 All right, title and interest in and to the application and its content are the property of Bush Coding.<br />
          4.2 You must not copy, modify or distribute the content without our permission.<br /><br />

          <strong>5. Modifications</strong><br />
          5.1 We reserve the right to modify these terms at any time.<br />
          5.2 Modifications will come into effect once published on our website.<br /><br />

          <strong>6. Contact</strong><br />
          For any questions about these terms, you can contact us at: <b className='break-all'>support@bushcoding.freshdesk.com</b>
        </p>
      </div>
    </div>
  );
};

export default AboutPage;
