import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-800">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <a href="https://bushcoding.com/" target='_blank' rel="noopener noreferrer" className="flex items-center">
                            <img src={process.env.PUBLIC_URL + '/bclw.svg'} className="h-20 me-3" alt="Bush Coding logo, from Classify web page" />
                        </a>
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold uppercase text-white">Follow us</h2>
                            <ul className="text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="https://www.instagram.com/bushcoding/" target='_blank' rel="noopener noreferrer" className="hover:underline">Instagram</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://www.facebook.com/people/Bush-Coding/61555543885636/" target='_blank' rel="noopener noreferrer" className="hover:underline">Facebook</a>
                                </li>
                                <li>
                                    <a href="https://x.com/TheBushCoding" target='_blank' rel="noopener noreferrer" className="hover:underline">Twitter X</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold uppercase text-white">Legal</h2>
                            <ul className="text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="/about" className="hover:underline">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/about" className="hover:underline">Terms &amp; Conditions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm sm:text-center text-gray-400">© {currentYear} <a href="https://bushcoding.com/" target='_blank' rel="noopener noreferrer" className="hover:underline">Bush Coding</a>. All Rights Reserved.
                    </span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
