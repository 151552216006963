import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  const isAuthenticated = localStorage.getItem('token') !== null;

  return (
    <div className="flex flex-col bg-gradient-to-r from-blue-400 via-blue-300 to-blue-300">
      <div className="text-white py-16 text-center flex items-center justify-center h-screen px-5">
        <div className="container mx-auto">
          <h1 className="text-5xl font-extrabold mb-4">Welcome to Classify!</h1>
          <p className="text-xl mb-8">The best file organizer.</p>
          {isAuthenticated ? (
            <Link
              to="/profile"
              className="inline-block bg-blue-400 hover:bg-blue-500 hover:shadow-inner text-white font-bold text-xl py-3 px-6 rounded-full transition duration-300">Go to my profile!</Link>
          ) : (
            <Link
              to="/register"
              className="inline-block bg-blue-400 hover:bg-blue-500 hover:shadow-inner text-white font-bold text-xl py-3 px-6 rounded-full transition duration-300">Create your account now!</Link>
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center py-20 bg-gray-800 text-white">
        <div className="container mx-auto flex flex-col md:flex-row items-center text-center flex items-center justify-center bg-gray-800">
          <div className="md:w-1/2 m-5">
            <h2 className="text-4xl font-bold mb-4">Who we are?</h2>
            <p className="text-lg">Welcome to Classify by Bush Coding, founded in 2022, our mission is to provide an efficient, user-friendly tool for file management that enhances productivity. We believe technology should simplify life, and we strive to create an intuitive, powerful, and accessible application. We are dedicated to high-quality standards in all aspects, from new features to customer support, ensuring exceptional service.</p>
          </div>
          <div className="m-5">
            <img src={process.env.PUBLIC_URL + '/cartons.svg'} alt="Cartoons logo by Classify, software of Bush Coding" className="w-80" />
          </div>
        </div>
      </div>

      <div className="py-20 bg-white">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold mb-4 text-center">Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-white">
            <div className="bg-gray-800 p-4 m-2 rounded-lg shadow-md text-center">
              <h3 className="text-xl font-bold mb-4">User-Friendly Interface</h3>
              <p>Classify offers an intuitive and easy-to-navigate interface, making file management simple and accessible for all users.</p>
              <div className="flex justify-center m-3">
                <img src={process.env.PUBLIC_URL + '/1.svg'} alt="Features by Classify, software of Bush Coding" className="w-40" />
              </div>
            </div>
            <div className="bg-gray-800 p-4 m-2 rounded-lg shadow-md text-center">
              <h3 className="text-xl font-bold mb-4">Secure File Organization</h3>
              <p>The application ensures that your files are organized efficiently and securely, keeping your information protected and easily accessible.</p>
              <div className="flex justify-center m-3">
                <img src={process.env.PUBLIC_URL + '/2.svg'} alt="Features by Classify, software of Bush Coding" className="w-40" />
              </div>
            </div>
            <div className="bg-gray-800 p-4 m-2 rounded-lg shadow-md text-center">
              <h3 className="text-xl font-bold mb-4">High-Quality Support</h3>
              <p>Classify is committed to providing exceptional customer support and continuous updates, ensuring a reliable and top-notch user experience.</p>
              <div className="flex justify-center m-3">
                <img src={process.env.PUBLIC_URL + '/3.svg'} alt="Features by Classify, software of Bush Coding" className="w-40" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="text-white py-20 text-center px-5">
        <div className="container mx-auto">
          <h2 className="text-5xl font-extrabold mb-4">Join us today!</h2>
          <p className="text-xl mb-8">Discover everything we offer and experience a higher level of organization in your files.</p>
          {isAuthenticated ? (
            <Link
              to="/profile"
              className="inline-block bg-blue-400 hover:bg-blue-500 hover:shadow-inner text-white font-bold text-xl py-3 px-6 rounded-full transition duration-300">Go to my profile!</Link>
          ) : (
            <Link
              to="/register"
              className="inline-block bg-blue-400 hover:bg-blue-500 hover:shadow-inner text-white font-bold text-xl py-3 px-6 rounded-full transition duration-300">Create your account now!</Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
